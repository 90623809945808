// import React, { useState, useEffect } from 'react';
// import api from '../../api/axiosConfig';
// import { Link } from 'react-router-dom';
// import { useSelector } from 'react-redux';
// import AdComponent from '../AdComponent';

// function BlogList() {
//   const [posts, setPosts] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const user = useSelector((state) => state.user);
//   const isAdmin = user && user.user_type === "Admin";

//   useEffect(() => {
//     const fetchPosts = async () => {
//       try {
//         const response = await api.get('/api/blog/posts/');
//         if (Array.isArray(response.data)) {
//           setPosts(response.data);
//         } else {
//           console.error('Unexpected response format:', response.data);
//           setPosts([]);
//         }
//       } catch (error) {
//         console.error('Error fetching posts:', error);
//         setPosts([]);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchPosts();
//   }, []);

//   const getPreviewText = (content) => {
//     try {
//       if (!content || !content.blocks) return '';
//       const text = content.blocks[0]?.data?.text || '';
//       const plainText = text.replace(/<[^>]*>/g, '');
//       return plainText.length > 200 
//         ? plainText.substring(0, 200) + '...' 
//         : plainText;
//     } catch (error) {
//       return '';
//     }
//   };

//   if (loading) {
//     return (
//       <div className="p-8">
//         <div className="flex justify-center items-center h-[60vh]">
//           <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-[#c99f20]"></div>
//         </div>
//       </div>
//     );
//   }

//   return (
//     <div className="p-8 min-h-screen bg-gradient-to-b from-gray-100 to-white">
//       <div className="container mx-auto px-4 py-16 mt-16">
//         <div className="flex justify-between items-center mb-12">
//           <h1 className="text-4xl font-bold text-gray-900 text-center flex-grow">
//             Latest Articles
//           </h1>
//           {isAdmin && (
//             <Link 
//               to="/admin/blog/new"
//               className="bg-[#c99f20] text-white px-4 py-2 rounded-md shadow-md hover:bg-[#b08b1a] transition-colors duration-300"
//             >
//               Create New Post
//             </Link>
//           )}
//         </div>
        
//         {/* Top Ad */}
//         <div className="mb-12">
//           <AdComponent adSlot="YOUR-AD-SLOT-1" />
//         </div>
        
//         <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
//           {posts.map((post, index) => (
//             <React.Fragment key={post.id}>
//               <div className="relative group bg-white rounded-xl shadow-lg overflow-hidden transform transition duration-300 hover:scale-105 hover:shadow-xl">
//                 {isAdmin && (
//                   <Link 
//                     to={`/admin/blog/edit/${post.slug}`}
//                     className="absolute top-2 right-2 z-10 bg-[#c99f20] text-white px-3 py-1 rounded-md shadow-md hover:bg-[#b08b1a] transition-colors duration-300"
//                     onClick={(e) => e.stopPropagation()}
//                   >
//                     Edit
//                   </Link>
//                 )}
//                 <Link 
//                   to={`/blog/${post.slug}`}
//                   className="block"
//                 >
//                   <div className="aspect-w-16 aspect-h-9">
//                     {post.featured_image ? (
//                       <img
//                         src={post.featured_image}
//                         alt={post.title}
//                         className="w-full h-48 object-cover"
//                       />
//                     ) : (
//                       <div className="w-full h-48 bg-gradient-to-r from-[#c99f20] to-gray-200 flex items-center justify-center">
//                         <span className="text-white text-xl font-semibold">
//                           WriterBeaz
//                         </span>
//                       </div>
//                     )}
//                   </div>
                  
//                   <div className="p-6">
//                     <h2 className="text-xl font-bold text-gray-900 mb-3 group-hover:text-[#c99f20] transition duration-300">
//                       {post.title}
//                     </h2>
                    
//                     <p className="text-gray-600 text-sm mb-4">
//                       {new Date(post.created_at).toLocaleDateString('en-US', {
//                         year: 'numeric',
//                         month: 'long',
//                         day: 'numeric'
//                       })}
//                     </p>
                    
//                     <p className="text-gray-700 line-clamp-3">
//                       {getPreviewText(post.content_json)}
//                     </p>
                    
//                     <div className="mt-4 flex items-center text-[#c99f20] font-semibold">
//                       Read More
//                       <svg 
//                         className="ml-2 w-4 h-4 group-hover:translate-x-2 transition-transform duration-300" 
//                         fill="none" 
//                         stroke="currentColor" 
//                         viewBox="0 0 24 24"
//                       >
//                         <path 
//                           strokeLinecap="round" 
//                           strokeLinejoin="round" 
//                           strokeWidth={2} 
//                           d="M14 5l7 7m0 0l-7 7m7-7H3" 
//                         />
//                       </svg>
//                     </div>
//                   </div>
//                 </Link>
//               </div>

//               {/* Insert ad after every 6th post */}
//               {(index + 1) % 6 === 0 && index !== posts.length - 1 && (
//                 <div className="col-span-full my-8">
//                   <AdComponent 
//                     adSlot={`YOUR-AD-SLOT-${Math.floor(index/6) + 2}`} 
//                     format="horizontal"
//                   />
//                 </div>
//               )}
//             </React.Fragment>
//           ))}
//         </div>

//         {/* Bottom Ad */}
//         <div className="mt-12">
//           <AdComponent adSlot="YOUR-AD-SLOT-5" />
//         </div>
//       </div>
//     </div>
//   );
// }

// export default BlogList;


import React, { useState, useEffect } from 'react';
import api from '../../api/axiosConfig';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import AdComponent from '../AdComponent';

function BlogList() {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const user = useSelector((state) => state.user);
  const isAdmin = user && user.user_type === "Admin";

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await api.get('/api/blog/posts/');
        if (Array.isArray(response.data)) {
          setPosts(response.data);
        } else {
          console.error('Unexpected response format:', response.data);
          setPosts([]);
        }
      } catch (error) {
        console.error('Error fetching posts:', error);
        setPosts([]);
      } finally {
        setLoading(false);
      }
    };

    fetchPosts();
  }, []);

  const getPreviewText = (content) => {
    try {
      if (!content || !content.blocks) return '';
      const text = content.blocks[0]?.data?.text || '';
      const plainText = text.replace(/<[^>]*>/g, '');
      return plainText.length > 200 
        ? plainText.substring(0, 200) + '...' 
        : plainText;
    } catch (error) {
      return '';
    }
  };

  if (loading) {
    return (
      <div className="p-8">
        <div className="flex justify-center items-center h-[60vh]">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-[#c99f20]"></div>
        </div>
      </div>
    );
  }

  return (
    <div className="p-8 min-h-screen bg-gradient-to-b from-gray-100 to-white">
      <div className="container mx-auto px-4 py-16 mt-16">
        <div className="flex justify-between items-center mb-12">
          <h1 className="text-4xl font-bold text-gray-900 text-center flex-grow">
            Latest Articles
          </h1>
          {isAdmin && (
            <Link 
              to="/admin/blog/new"
              className="bg-[#c99f20] text-white px-4 py-2 rounded-md shadow-md hover:bg-[#b08b1a] transition-colors duration-300"
            >
              Create New Post
            </Link>
          )}
        </div>
        
        {/* Top Ad */}
        <div className="mb-12">
          <AdComponent adSlot="3869436166" />
        </div>
        
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {posts.map((post, index) => (
            <React.Fragment key={post.id}>
              <div className="relative group bg-white rounded-xl shadow-lg overflow-hidden transform transition duration-300 hover:scale-105 hover:shadow-xl">
                {isAdmin && (
                  <Link 
                    to={`/admin/blog/edit/${post.slug}`}
                    className="absolute top-2 right-2 z-10 bg-[#c99f20] text-white px-3 py-1 rounded-md shadow-md hover:bg-[#b08b1a] transition-colors duration-300"
                    onClick={(e) => e.stopPropagation()}
                  >
                    Edit
                  </Link>
                )}
                <Link 
                  to={`/blog/${post.slug}`}
                  className="block"
                >
                  <div className="aspect-w-16 aspect-h-9">
                    {post.featured_image ? (
                      <img
                        src={post.featured_image}
                        alt={post.title}
                        className="w-full h-48 object-cover"
                      />
                    ) : (
                      <div className="w-full h-48 bg-gradient-to-r from-[#c99f20] to-gray-200 flex items-center justify-center">
                        <span className="text-white text-xl font-semibold">
                          WriterBeaz
                        </span>
                      </div>
                    )}
                  </div>
                  
                  <div className="p-6">
                    <h2 className="text-xl font-bold text-gray-900 mb-3 group-hover:text-[#c99f20] transition duration-300">
                      {post.title}
                    </h2>
                    
                    <p className="text-gray-600 text-sm mb-4">
                      {new Date(post.created_at).toLocaleDateString('en-US', {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric'
                      })}
                    </p>
                    
                    <p className="text-gray-700 line-clamp-3">
                      {getPreviewText(post.content_json)}
                    </p>
                    
                    <div className="mt-4 flex items-center text-[#c99f20] font-semibold">
                      Read More
                      <svg 
                        className="ml-2 w-4 h-4 group-hover:translate-x-2 transition-transform duration-300" 
                        fill="none" 
                        stroke="currentColor" 
                        viewBox="0 0 24 24"
                      >
                        <path 
                          strokeLinecap="round" 
                          strokeLinejoin="round" 
                          strokeWidth={2} 
                          d="M14 5l7 7m0 0l-7 7m7-7H3" 
                        />
                      </svg>
                    </div>
                  </div>
                </Link>
              </div>

              {/* Insert ad after every 6th post */}
              {(index + 1) % 6 === 0 && index !== posts.length - 1 && (
                <div className="col-span-full my-8">
                  <AdComponent 
                    adSlot={`YOUR-AD-SLOT-${Math.floor(index/6) + 2}`} 
                    format="horizontal"
                  />
                </div>
              )}
            </React.Fragment>
          ))}
        </div>

        {/* Bottom Ad */}
        <div className="mt-12">
          <AdComponent adSlot="YOUR-AD-SLOT-5" />
        </div>
      </div>
    </div>
  );
}

export default BlogList;
