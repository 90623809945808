// import { Route, Routes, Navigate, BrowserRouter } from "react-router-dom";
// import UserRouter from "./Router/UserRouter";
// import TutorRouter from "./Router/TutorRouter";
// import AdminRouter from "./Router/AdminRouter";
// import AdminBlog from './Components/Admin/AdminBlog';
// import Home from "./Components/User/Home";
// import Login from "./Components/Auth/Login";
// import Signup from "./Components/Auth/Signup";
// import OtpVerification from "./Components/Auth/OtpVerification";
// import Forgot from "./Components/Auth/Forgot";
// import Reset from "./Components/Auth/Reset";
// import ChatPage from "./Components/Chat/ChatPage";
// import SupportChatPage from "./Components/Chat/SupportChat";
// import { useSelector } from "react-redux";
// import BlogList from './Components/Blog/BlogList';
// import BlogPost from './Components/Blog/BlogPost';
// import AdminBlogEditor from './Components/Admin/AdminBlogEditor';

// function App() {
//   const user = useSelector((state) => state.user);

//   const isAdmin = user && user.user_type === "Admin";
//   const isUser = user && user.user_type === "User";
//   const isTutor = user && user.user_type === "Tutor";

//   return (
//     <>
//       <BrowserRouter>
//         <Routes>
//           <Route path="/" element={<Home />} />
//           <Route path="/login" element={<Login />} />
//           <Route path="/signup" element={<Signup />} />
//           <Route path="/forgot-password" element={<Forgot />} />
//           <Route path="/forgot-password/:id" element={<Reset />} />
//           <Route path="/reset-password/:id" element={<Reset />} />
//           <Route path="/otp-verification" element={<OtpVerification />} />
          
//           {/* USER */}
//           {isUser || isAdmin ? (
//             <Route path="/user/*" element={<UserRouter />} />
//           ) : (
//             <Route path="/user/*" element={<Navigate to="/login" />} />
//           )}

//           {/* FREELANCER */}
//           {isTutor || isAdmin ? (
//             <Route path="/tutor/*" element={<TutorRouter />} />
//           ) : (
//             <Route path="/tutor/*" element={<Navigate to="/login" />} />
//           )}

//           {/* ADMIN */}
//           {isAdmin ? (
//             <Route path="/admin/*" element={<AdminRouter />} />
//           ) : (
//             <Route path="/admin/*" element={<Navigate to="/login" />} />
//           )}

//           <Route path="/blog" element={<BlogList />} />
//           <Route path="/blog/:slug" element={<BlogPost />} />
//         </Routes>
//       </BrowserRouter>
//       <ChatPage />
//       <SupportChatPage />
//     </>
//   );
// }

// export default App;


import { Route, Routes, Navigate, BrowserRouter } from "react-router-dom";
import UserRouter from "./Router/UserRouter";
import TutorRouter from "./Router/TutorRouter";
import AdminRouter from "./Router/AdminRouter";
import AdminBlog from './Components/Admin/AdminBlog';
import Home from "./Components/User/Home";
import Login from "./Components/Auth/Login";
import Signup from "./Components/Auth/Signup";
import OtpVerification from "./Components/Auth/OtpVerification";
import Forgot from "./Components/Auth/Forgot";
import Reset from "./Components/Auth/Reset";
import ChatPage from "./Components/Chat/ChatPage";
import SupportChatPage from "./Components/Chat/SupportChat";
import { useSelector } from "react-redux";
import BlogList from './Components/Blog/BlogList';
import BlogPost from './Components/Blog/BlogPost';
import AdminBlogEditor from './Components/Admin/AdminBlogEditor';
import Terms from './Components/Terms/Terms';
import AdminTermsEditor from './Components/Admin/AdminTermsEditor';

function App() {
  const user = useSelector((state) => state.user);

  const isAdmin = user && user.user_type === "Admin";
  const isUser = user && user.user_type === "User";
  const isTutor = user && user.user_type === "Tutor";

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/forgot-password" element={<Forgot />} />
          <Route path="/forgot-password/:id" element={<Reset />} />
          <Route path="/reset-password/:id" element={<Reset />} />
          <Route path="/otp-verification" element={<OtpVerification />} />
          
          {/* Terms of Use Route - Public Access */}
          <Route path="/terms-of-use" element={<Terms />} />
          
          {/* USER */}
          {isUser || isAdmin ? (
            <Route path="/user/*" element={<UserRouter />} />
          ) : (
            <Route path="/user/*" element={<Navigate to="/login" />} />
          )}

          {/* FREELANCER */}
          {isTutor || isAdmin ? (
            <Route path="/tutor/*" element={<TutorRouter />} />
          ) : (
            <Route path="/tutor/*" element={<Navigate to="/login" />} />
          )}

          {/* ADMIN */}
          {isAdmin ? (
            <Route path="/admin/*" element={<AdminRouter />} />
          ) : (
            <Route path="/admin/*" element={<Navigate to="/login" />} />
          )}

          <Route path="/blog" element={<BlogList />} />
          <Route path="/blog/:slug" element={<BlogPost />} />
        </Routes>
      </BrowserRouter>
      <ChatPage />
      <SupportChatPage />
    </>
  );
}

export default App;
