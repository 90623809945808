import React from 'react';
import Navbar from '../Layout/Navbar';
import Footer from '../Layout/Footer';

function Terms() {
  return (
    <>
      <Navbar />
      <div className="container mx-auto px-6 py-8 max-w-7xl">
        <div className="mx-auto">
          <h1 className="text-4xl font-bold mb-8 text-center">Terms and Conditions</h1>
          
          <div className="prose prose-lg max-w-none">
            {/* Introduction */}
            <section className="mb-8">
              <p className="mb-4">
                Welcome to writerbeaz.com. These Terms of Use govern the relationship between writerbeaz.com ("The platform") and its users ("you" or "User") regarding the use of the platform. By accessing or using our services, you agree to comply with these terms and conditions. Please read them carefully before proceeding.
              </p>
              <p className="mb-4">
                The introduction sets the tone for the legal relationship between writerbeaz.com and its users. It establishes the scope and applicability of the Terms of Use to anyone who accesses or uses the services provided by writerbeaz.com. Users are urged to carefully review the terms and conditions outlined herein before proceeding to use the platform.
              </p>
            </section>

            {/* User Registration and Orders */}
            <section className="mb-8">
              <h2 className="text-2xl font-semibold mb-4">User Registration and Orders</h2>
              <p className="mb-4">To access the services offered by writerbeaz.com, Users must register an account.</p>
              <ol className="list-decimal pl-6 mb-4">
                <li className="mb-2">Account creation requires providing accurate and up-to-date information. Once registered, Clients can post writing projects, and Writers can bid on available orders.</li>
                <li className="mb-2">Clients are responsible for providing clear and detailed instructions for their projects to ensure writers understand the requirements accurately.</li>
                <li className="mb-2">When registering, Users must agree to abide by the Terms of Use and Privacy Policy of Writerbeaz.com.</li>
                <li className="mb-2">This agreement forms a binding contract between the User and Writerbeaz.com.</li>
                <li className="mb-2">Users are responsible for maintaining the security of their account credentials and must not share their login information with others.</li>
                <li className="mb-2">Any unauthorized use of an account must be reported immediately to Writerbeaz.com.</li>
                <li className="mb-2">Writerbeaz.com reserves the right to verify the identity of Users and may request additional information to authenticate accounts or validate transactions.</li>
              </ol>
            </section>

            {/* Information Accuracy and Disclaimer */}
            <section className="mb-8">
              <h2 className="text-2xl font-semibold mb-4">Information Accuracy and Disclaimer</h2>
              <p className="mb-4">Writerbeaz.com strives to provide accurate and reliable information on its platform.</p>
              <ol className="list-decimal pl-6 mb-4">
                <li className="mb-3">
                  Writerbeaz.com is committed to providing accurate, reliable, and up-to-date information on its platform. While we strive to ensure that all information presented is correct and comprehensive, we do not guarantee the absolute accuracy or completeness of all content available on the platform. Information may change or be updated without prior notice, and Writerbeaz.com does not warrant that all content will be free from errors, omissions, or inaccuracies.
                </li>
                <li className="mb-3">
                  Users are strongly encouraged to independently verify any information obtained from Writerbeaz.com before relying on it for decision-making or other purposes. This includes, but is not limited to, factual data, recommendations, and content provided by other Users or third-party sources. Writerbeaz.com disclaims any responsibility for decisions made based on information that has not been independently verified by the User.
                </li>
                <li className="mb-3">
                  Users bear sole responsibility for ensuring the accuracy, legality, and appropriateness of any content they post, submit, or otherwise make available on Writerbeaz.com. This includes, but is not limited to, compliance with intellectual property laws, data protection regulations, and any other applicable legal standards.
                </li>
                <li className="mb-3">
                  Writerbeaz.com explicitly disclaims liability for any damages, losses, or harm resulting from reliance on inaccurate, incomplete, or misleading information available on the platform.
                </li>
                <li className="mb-3">
                  Writerbeaz.com reserves the exclusive right to remove, modify, or restrict access to any content that violates these Terms of Use, our Content Guidelines, or any applicable laws and regulations.
                </li>
                <li className="mb-3">
                  In the interest of maintaining a trustworthy and compliant platform, Writerbeaz.com actively enforces its Terms of Use and Content Guidelines.
                </li>
              </ol>
            </section>

            {/* Service Availability and Compliance */}
            <section className="mb-8">
              <h2 className="text-2xl font-semibold mb-4">Service Availability and Compliance</h2>
              <ol className="list-decimal pl-6 mb-4">
                <li className="mb-3">
                  Writerbeaz.com strives to offer its services to a global audience, providing access to its platform across various jurisdictions. However, it is the sole responsibility of Users to ensure that their use of the platform is in full compliance with the local laws, regulations, and legal requirements of their respective countries or regions.
                </li>
                <li className="mb-3">
                  Users expressly agree not to engage in any activities while using the Writerbeaz.com platform that are unlawful, fraudulent, or infringe upon the rights of others.
                </li>
                <li className="mb-3">
                  Writerbeaz.com may impose restrictions on access to certain features or services based on geographic location or in response to legal restrictions imposed by local or international authorities.
                </li>
                <li className="mb-3">
                  Users must ensure that any content they post, submit, or otherwise make available on Writerbeaz.com does not infringe upon the intellectual property rights of others.
                </li>
                <li className="mb-3">
                  Writerbeaz.com disclaims any liability for actions taken by Users that are in violation of local laws or third-party rights.
                </li>
                <li className="mb-3">
                  Writerbeaz.com reserves the right to enforce compliance with these terms by taking necessary actions.
                </li>
              </ol>
            </section>

            {/* Communications, Privacy, and Data Protection */}
            <section className="mb-8">
              <h2 className="text-2xl font-semibold mb-4">Communications, Privacy, and Data Protection</h2>
              <ol className="list-decimal pl-6 mb-4">
                <li className="mb-3">
                  By utilizing the services offered on the writerbeaz.com platform, Users hereby expressly consent to receive communications from writerbeaz.com related to their account activities, order processing, and platform updates.
                </li>
                <li className="mb-3">
                  Writerbeaz.com is deeply committed to safeguarding the privacy of its Users and strictly adheres to the privacy policies outlined in our comprehensive Privacy Policy. The platform collects, processes, and stores personal information in full compliance with applicable data protection laws and regulations.
                </li>
                <li className="mb-3">
                  Users are granted the legal right to access, rectify, or request the deletion of their personal information stored by writerbeaz.com, as provided by applicable data protection laws.
                </li>
                <li className="mb-3">
                  Users agree to maintain the confidentiality of their account credentials, including but not limited to their email addresses and passwords associated with their writerbeaz.com account.
                </li>
                <li className="mb-3">
                  Writerbeaz.com shall not be held liable for any loss, damage, or harm resulting from the unauthorized access to or misuse of a User's account information.
                </li>
                <li className="mb-3">
                  Writerbeaz.com reserves the right to modify or update its Privacy Policy, data protection practices, and security measures at any time, in accordance with applicable law.
                </li>
              </ol>
            </section>

            {/* Suspension and Termination of Accounts */}
            <section className="mb-8">
              <h2 className="text-2xl font-semibold mb-4">Suspension and Termination of Accounts</h2>
              <ol className="list-decimal pl-6 mb-4">
                <li className="mb-3">
                  WriterBeaz.com reserves the right to suspend or terminate User accounts and access to the platform for violations of these Terms of Use or for other reasons deemed necessary by WriterBeaz.com.
                </li>
                <li className="mb-3">
                  Users may appeal account suspensions or terminations by contacting our support team.
                </li>
                <li className="mb-3">
                  Writerbeaz.com may also suspend or terminate accounts that remain inactive for an extended period or are associated with fraudulent or abusive activities.
                </li>
              </ol>
            </section>

            {/* Order Completion and Payment Release */}
            <section className="mb-8">
              <h2 className="text-2xl font-semibold mb-4">Order Completion and Payment Release</h2>
              <ol className="list-decimal pl-6 mb-4">
                <li className="mb-3">
                  <strong>Acceptance of Orders and Execution Obligations:</strong><br />
                  Upon the formal acceptance of an order by the Writer, there arises a binding obligation to execute and fulfill the specific requirements outlined in the order, strictly adhering to the agreed-upon timeframe.
                </li>
                <li className="mb-3">
                  Clients are entitled to request revisions to the submitted work if it does not meet the agreed-upon standards or specific expectations.
                </li>
                <li className="mb-3">
                  Upon the Client's satisfaction with the completed work, they may authorize the release of payment to the Writer.
                </li>
                <li className="mb-3">
                  The completion and delivery of the work are strictly governed by the terms mutually agreed upon by both the Client and the Writer at the outset of the contractual relationship.
                </li>
                <li className="mb-3">
                  Any proposed modifications to the scope of work, deadlines, or other essential terms of the contract must be negotiated and agreed upon by both parties.
                </li>
                <li className="mb-3">
                  Clients are strongly encouraged to provide constructive feedback on the work delivered by the Writer.
                </li>
                <li className="mb-3">
                  In the event of a dispute arising from the interpretation or execution of this agreement, both parties agree to first engage in good faith negotiations.
                </li>
                <li className="mb-3">
                  Writerbeaz.com reserves the absolute right, at its sole discretion, to make final decisions concerning any disputes, claims, or discrepancies.
                </li>
              </ol>
            </section>

            {/* Refunds, Disputes, and Quality Assurance */}
            <section className="mb-8">
              <h2 className="text-2xl font-semibold mb-4">Refunds, Disputes, and Quality Assurance</h2>
              <ol className="list-decimal pl-6 mb-4">
                <li className="mb-3">
                  In the event of disputes or dissatisfaction with the completed work, Clients are entitled to request revisions or seek formal intervention from Writerbeaz.com's Quality Assurance team.
                </li>
                <li className="mb-3">
                  Refunds may be granted under circumstances where the completed work fails to meet the specified requirements or if there are unresolved disputes between the Client and the Writer that cannot be satisfactorily resolved through revisions or mediation.
                </li>
                <li className="mb-3">
                  Once a refund request is approved following the investigation, refunds are typically processed within 30 days.
                </li>
                <li className="mb-3">
                  The refund amount awarded to the Client may not necessarily reflect the full amount paid for the service.
                </li>
                <li className="mb-3">
                  Writerbeaz.com reserves the unequivocal right to withhold payment or impose penalties on Writers who fail to meet the established quality standards or who engage in fraudulent, deceptive, or unethical activities.
                </li>
                <li className="mb-3">
                  All decisions made by writerbeaz.com regarding disputes, refund requests, penalties, and the enforcement of quality standards are final and binding.
                </li>
              </ol>
            </section>

            {/* Writer Qualification Process */}
            <section className="mb-8">
              <h2 className="text-2xl font-semibold mb-4">Writer Qualification Process</h2>
              <ol className="list-decimal pl-6 mb-4">
                <li className="mb-3">
                  At Writerbeaz.com, we uphold a stringent writer qualification process to ensure that only the most competent and dedicated writers are hired to deliver exceptional writing services to our clients.
                </li>
                <li className="mb-3">
                  Interviews: Prospective writers undergo a series of interviews conducted by our experienced team of editors and administrators.
                </li>
                <li className="mb-3">
                  Tests: Writers are required to complete a set of comprehensive tests designed to evaluate their writing proficiency, research skills, ability to adhere to formatting guidelines, and understanding of plagiarism and citation standards.
                </li>
                <li className="mb-3">
                  Commitment to Quality: We prioritize writers who demonstrate a strong commitment to delivering high-quality papers that meet or exceed client expectations.
                </li>
                <li className="mb-3">
                  Competitive Hiring: The hiring process at Writerbeaz.com is highly competitive, and only experts who showcase exceptional skills, dedication, and professionalism are selected to join our team.
                </li>
                <li className="mb-3">
                  Continuous Evaluation: Once hired, writers are subject to continuous evaluation and feedback to ensure that they uphold our standards of excellence.
                </li>
                <li className="mb-3">
                  Commitment to Client Satisfaction: Our ultimate goal is to ensure client satisfaction by providing top-quality writing services that meet their specific needs and requirements.
                </li>
                <li className="mb-3">
                  Equal Opportunity: We provide equal opportunities to all individuals, regardless of race, color, ethnicity, nationality, religion, gender, sexual orientation, age, disability, marital status, or any other characteristic protected by applicable laws.
                </li>
                <li className="mb-3">
                  By adhering to our rigorous writer qualification process, Writerbeaz.com maintains a team of talented and dedicated writers who are committed to delivering excellence in every project they undertake.
                </li>
              </ol>
            </section>

            {/* Modification of Terms */}
            <section className="mb-8">
              <h2 className="text-2xl font-semibold mb-4">Modification of Terms</h2>
              <ol className="list-decimal pl-6 mb-4">
                <li className="mb-3">
                  Writerbeaz.com reserves the right to modify or update these Terms of Use at any time without prior notice.
                </li>
                <li className="mb-3">
                  Updated versions of the Terms of Use will be posted on the platform, and Users are responsible for reviewing them periodically.
                </li>
                <li className="mb-3">
                  Changes to the Terms of Use will be effective upon posting on the platform.
                </li>
                <li className="mb-3">
                  Continued use of the platform after the effective date of the changes constitutes acceptance of the revised Terms of Use.
                </li>
              </ol>
            </section>

            {/* Closing Statement */}
            <section className="mb-8">
              <p className="text-gray-700">
                By using Writerbeaz.com, you acknowledge and agree to be bound by these Terms of Use. If you have any questions or concerns regarding these terms, please contact us for clarification. Writerbeaz.com is committed to providing a transparent and reliable platform for Clients and Writers to engage in professional writing services.
              </p>
            </section>
          </div>

          <p className="text-sm text-gray-500 mt-8 text-center">
            Last updated: February 2024
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Terms;
