// import axios from "axios";
// import store from "../Redux/store";
// import {
//   setAccessToken,
//   setRefreshToken,
//   setTokenExpiry,
//   clearUser,
// } from "../Redux/store";
// import { toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";

// const get_timezone = () => {
//   const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
//   return timezone;
// };

// const dispatch = store.dispatch;
// const api = axios.create({
//   baseURL: process.env.REACT_APP_API_URL,
//   headers: {
//     "Content-Type": "application/json",
//     timezone: get_timezone(),
//   },
//   withCredentials: true,
// });

// // Request interceptor
// api.interceptors.request.use(
//   (config) => {
//     console.log('Request URL:', config.url);
//     console.log('Request Method:', config.method);
    
//     // Skip auth only for GET requests to blog endpoints
//     const isBlogGetRequest = config.method === 'get' && (
//       config.url.includes('/api/blog/posts') || 
//       config.url.includes('/api/blog/post')
//     );

//     if (!isBlogGetRequest) {
//       const accessToken = store.getState().accessToken;
//       if (accessToken) {
//         config.headers.Authorization = `Bearer ${accessToken}`;
//         console.log('Added Authorization header:', config.headers.Authorization);
//       } else {
//         console.warn('No access token found for protected request');
//       }
//     }

//     // For FormData requests, don't set Content-Type
//     if (config.data instanceof FormData) {
//       delete config.headers['Content-Type'];
//     }

//     return config;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );

// // Axios interceptor for response errors
// api.interceptors.response.use(
//   (response) => {
//     return response;
//   },
//   async (error) => {
//     // Don't try to refresh token for blog GET requests
//     if (error.config.method === 'get' && (
//       error.config.url.includes('/api/blog/posts') || 
//       error.config.url.includes('/api/blog/post')
//     )) {
//       return Promise.reject(error);
//     }

//     if (error.response && error.response.status === 401) {
//       // Unauthorized error, possibly due to an expired access token
//       const refreshToken = store.getState().refreshToken;
//       if (refreshToken) {
//         try {
//           // Send a request to refresh the access token
//           const response = await api.post("/token/refresh/", {
//             refresh: refreshToken,
//           });

//           if (response.status === 200) {
//             const newAccessToken = response.data.access;
//             const newTokenExpiry = response.data.token_expiry;

//             // Update access token and token expiry in Redux and local storage
//             dispatch(setAccessToken(newAccessToken));
//             localStorage.setItem("accessToken", newAccessToken);
//             dispatch(setTokenExpiry(newTokenExpiry));
//             localStorage.setItem("tokenExpiry", newTokenExpiry);

//             // Retry the original request with the new access token
//             error.config.headers.Authorization = `Bearer ${newAccessToken}`;
//             return axios.request(error.config);
//           } else {
//             dispatch(clearUser());
//             toast.error("Authentication Token expired. Please login!");
//           }
//         } catch (refreshError) {
//           dispatch(clearUser());
//         }
//       } else {
//         dispatch(clearUser());
//       }
//     }
//     return Promise.reject(error);
//   }
// );

// export default api;


import axios from "axios";
import store from "../Redux/store";
import {
  setAccessToken,
  setRefreshToken,
  setTokenExpiry,
  clearUser,
} from "../Redux/store";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const get_timezone = () => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return timezone;
};

const dispatch = store.dispatch;
const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
    timezone: get_timezone(),
  },
  withCredentials: true,
});

// Request interceptor
api.interceptors.request.use(
  (config) => {
    console.log('Request URL:', config.url);
    console.log('Request Method:', config.method);
    
    // Skip auth for GET requests to blog endpoints and terms-of-use
    const isPublicGetRequest = config.method === 'get' && (
      config.url.includes('/api/blog/posts') || 
      config.url.includes('/api/blog/post') ||
      config.url.includes('/api/terms-of-use')
    );

    if (!isPublicGetRequest) {
      const accessToken = store.getState().accessToken;
      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
        console.log('Added Authorization header:', config.headers.Authorization);
      } else {
        console.warn('No access token found for protected request');
      }
    }

    // For FormData requests, don't set Content-Type
    if (config.data instanceof FormData) {
      delete config.headers['Content-Type'];
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Axios interceptor for response errors
api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    // Don't try to refresh token for public GET requests
    if (error.config.method === 'get' && (
      error.config.url.includes('/api/blog/posts') || 
      error.config.url.includes('/api/blog/post') ||
      error.config.url.includes('/api/terms-of-use')
    )) {
      return Promise.reject(error);
    }

    if (error.response && error.response.status === 401) {
      // Unauthorized error, possibly due to an expired access token
      const refreshToken = store.getState().refreshToken;
      if (refreshToken) {
        try {
          // Send a request to refresh the access token
          const response = await api.post("/token/refresh/", {
            refresh: refreshToken,
          });

          if (response.status === 200) {
            const newAccessToken = response.data.access;
            const newTokenExpiry = response.data.token_expiry;

            // Update access token and token expiry in Redux and local storage
            dispatch(setAccessToken(newAccessToken));
            localStorage.setItem("accessToken", newAccessToken);
            dispatch(setTokenExpiry(newTokenExpiry));
            localStorage.setItem("tokenExpiry", newTokenExpiry);

            // Retry the original request with the new access token
            error.config.headers.Authorization = `Bearer ${newAccessToken}`;
            return axios.request(error.config);
          } else {
            dispatch(clearUser());
            toast.error("Authentication Token expired. Please login!");
          }
        } catch (refreshError) {
          dispatch(clearUser());
        }
      } else {
        dispatch(clearUser());
      }
    }
    return Promise.reject(error);
  }
);

export default api;
