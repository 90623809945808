// import React, { useState, useEffect } from 'react';
// import { useParams, useNavigate } from 'react-router-dom';
// import ReactQuill from 'react-quill';
// import 'react-quill/dist/quill.snow.css';
// import api from '../../api/axiosConfig';
// import { useSelector } from 'react-redux';
// import store from '../../Redux/store';

// function AdminBlogEditor() {
//   const { slug } = useParams();
//   const navigate = useNavigate();
//   const [loading, setLoading] = useState(false);
//   const [title, setTitle] = useState('');
//   const [content, setContent] = useState('');
//   const [featuredImage, setFeaturedImage] = useState(null);
//   const [isPublished, setIsPublished] = useState(false);
//   const [currentImageUrl, setCurrentImageUrl] = useState('');

//   const user = useSelector((state) => state.user);
//   const accessToken = useSelector((state) => state.accessToken);

//   useEffect(() => {
//     console.log('Current user:', user);
//     console.log('Access token:', accessToken);
//     console.log('User type:', user?.user_type);
//   }, [user, accessToken]);

//   // Fetch existing blog post data if editing
//   useEffect(() => {
//     const fetchPost = async () => {
//       try {
//         if (slug) {
//           const response = await api.get(`/api/blog/posts/${slug}/`);
//           const post = response.data;
          
//           setTitle(post.title);
          
//           // Handle content from JSON structure
//           if (post.content_json && post.content_json.blocks) {
//             const textContent = post.content_json.blocks
//               .map(block => block.data.text)
//               .join('\n\n');
//             // Remove surrounding quotes if they exist
//             setContent(textContent.replace(/^"|"$/g, ''));
//           }
          
//           setIsPublished(post.is_published);
//           setCurrentImageUrl(post.featured_image || '');
//         }
//       } catch (error) {
//         console.error('Error fetching post:', error);
//       }
//     };

//     fetchPost();
//   }, [slug]);

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     setLoading(true);

//     try {
//       console.log('Current auth token:', store.getState().accessToken);
//       console.log('Current user type:', store.getState().user?.user_type);

//       const formData = new FormData();
//       formData.append('title', title);
//       formData.append('content', content);
//       formData.append('is_published', isPublished);
//       if (featuredImage) {
//         formData.append('featured_image', featuredImage);
//       }

//       const config = {
//         headers: {
//           Authorization: `Bearer ${store.getState().accessToken}`,
//         },
//       };

//       console.log('Request config:', config);

//       let response;
//       if (slug) {
//         // Update existing post
//         response = await api.put(`/api/blog/posts/${slug}/`, formData, config);
//       } else {
//         // Create new post
//         response = await api.post('/api/blog/posts/', formData, config);
//       }

//       console.log('Post saved:', response.data);
//       navigate('/admin/blog');
//     } catch (error) {
//       console.error('Error saving post:', error);
//       if (error.response) {
//         console.error('Full error response:', error.response);
//         console.error('Error status:', error.response.status);
//         console.error('Error data:', error.response.data);
//         if (error.response.status === 403) {
//           alert(`Authentication error: ${JSON.stringify(error.response.data)}`);
//         } else {
//           alert(`Error: ${JSON.stringify(error.response.data)}`);
//         }
//       }
//     } finally {
//       setLoading(false);
//     }
//   };

//   const modules = {
//     toolbar: [
//       [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
//       ['bold', 'italic', 'underline', 'strike'],
//       [{ 'list': 'ordered'}, { 'list': 'bullet' }],
//       [{ 'script': 'sub'}, { 'script': 'super' }],
//       [{ 'indent': '-1'}, { 'indent': '+1' }],
//       [{ 'direction': 'rtl' }],
//       [{ 'color': [] }, { 'background': [] }],
//       [{ 'align': [] }],
//       ['link', 'image'],
//       ['clean']
//     ],
//   };

//   return (
//     <div className="p-6">
//       <h1 className="text-2xl font-bold mb-6">
//         {slug ? 'Edit Blog Post' : 'Create New Blog Post'}
//       </h1>

//       <form onSubmit={handleSubmit} className="space-y-6">
//         {/* Title Input */}
//         <div>
//           <label className="block text-sm font-medium text-gray-700">Title</label>
//           <input
//             type="text"
//             value={title}
//             onChange={(e) => setTitle(e.target.value)}
//             className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2"
//             required
//           />
//         </div>

//         {/* Featured Image */}
//         <div>
//           <label className="block text-sm font-medium text-gray-700">Featured Image</label>
//           {currentImageUrl && (
//             <div className="mb-2">
//               <img 
//                 src={currentImageUrl} 
//                 alt="Current featured" 
//                 className="h-32 object-cover mb-2"
//               />
//               <p className="text-sm text-gray-600">Current image</p>
//             </div>
//           )}
//           <input
//             type="file"
//             onChange={(e) => setFeaturedImage(e.target.files[0])}
//             className="mt-1 block w-full"
//             accept="image/*"
//           />
//         </div>

//         {/* Rich Text Editor */}
//         <div>
//           <label className="block text-sm font-medium text-gray-700 mb-2">Content</label>
//           <ReactQuill
//             value={content}
//             onChange={(content) => setContent(content)}
//             modules={modules}
//             className="h-96 mb-12"
//           />
//         </div>

//         {/* Publish Toggle */}
//         <div className="flex items-center">
//           <input
//             type="checkbox"
//             checked={isPublished}
//             onChange={(e) => setIsPublished(e.target.checked)}
//             className="h-4 w-4 rounded border-gray-300"
//           />
//           <label className="ml-2 block text-sm text-gray-900">
//             Publish this post
//           </label>
//         </div>

//         {/* Submit Button */}
//         <div>
//           <button
//             type="submit"
//             disabled={loading}
//             className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
//           >
//             {loading ? 'Saving...' : (slug ? 'Update Post' : 'Create Post')}
//           </button>
//         </div>
//       </form>
//     </div>
//   );
// }

// export default AdminBlogEditor; 

import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import api from '../../api/axiosConfig';
import { useSelector } from 'react-redux';
import store from '../../Redux/store';

function AdminBlogEditor() {
  const { slug } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [featuredImage, setFeaturedImage] = useState(null);
  const [isPublished, setIsPublished] = useState(false);
  const [currentImageUrl, setCurrentImageUrl] = useState('');

  const user = useSelector((state) => state.user);
  const accessToken = useSelector((state) => state.accessToken);

  useEffect(() => {
    console.log('Current user:', user);
    console.log('Access token:', accessToken);
    console.log('User type:', user?.user_type);
  }, [user, accessToken]);

  // Fetch existing blog post data if editing
  useEffect(() => {
    const fetchPost = async () => {
      try {
        if (slug) {
          const response = await api.get(`/api/blog/posts/${slug}/`);
          const post = response.data;
          
          setTitle(post.title);
          
          // Handle content from content_json
          if (post.content_json && post.content_json.blocks) {
            const block = post.content_json.blocks[0];
            if (block && block.data && block.data.text) {
              setContent(block.data.text);
            }
          }
          
          setIsPublished(post.is_published);
          setCurrentImageUrl(post.featured_image || '');
        }
      } catch (error) {
        console.error('Error fetching post:', error);
      }
    };

    fetchPost();
  }, [slug]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const accessToken = store.getState().accessToken;
      const config = {
        headers: {
          'Authorization': `Bearer ${accessToken}`
        }
      };
      
      const formData = new FormData();
      formData.append('title', title);
      
      // Send content directly as a string
      // The serializer expects 'content', not 'content_json'
      formData.append('content', content);

      formData.append('is_published', isPublished);
      if (featuredImage) {
        formData.append('featured_image', featuredImage);
      }

      // Debug logging
      console.log('Content being sent:', content);

      let response;
      if (slug) {
        response = await api.put(`/api/blog/posts/${slug}/`, formData, config);
      } else {
        response = await api.post('/api/blog/posts/', formData, config);
      }

      console.log('Post saved:', response.data);
      navigate('/admin/blog');
    } catch (error) {
      console.error('Error details:', {
        status: error.response?.status,
        headers: error.response?.headers,
        data: error.response?.data,
        config: error.config,
        token: store.getState().accessToken
      });
      console.error('Error saving post:', error);
      if (error.response) {
        console.error('Full error response:', error.response);
        console.error('Error status:', error.response.status);
        console.error('Error data:', error.response.data);
        if (error.response.status === 403) {
          alert(`Authentication error: ${JSON.stringify(error.response.data)}`);
        } else {
          alert(`Error: ${JSON.stringify(error.response.data)}`);
        }
      }
    } finally {
      setLoading(false);
    }
  };

  const modules = {
    toolbar: [
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      [{ 'script': 'sub'}, { 'script': 'super' }],
      [{ 'indent': '-1'}, { 'indent': '+1' }],
      [{ 'direction': 'rtl' }],
      [{ 'color': [] }, { 'background': [] }],
      [{ 'align': [] }],
      ['link', 'image'],
      ['clean']
    ],
  };

  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold mb-6">
        {slug ? 'Edit Blog Post' : 'Create New Blog Post'}
      </h1>

      <form onSubmit={handleSubmit} className="space-y-6">
        {/* Title Input */}
        <div>
          <label className="block text-sm font-medium text-gray-700">Title</label>
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2"
            required
          />
        </div>

        {/* Featured Image */}
        <div>
          <label className="block text-sm font-medium text-gray-700">Featured Image</label>
          {currentImageUrl && (
            <div className="mb-2">
              <img 
                src={currentImageUrl} 
                alt="Current featured" 
                className="h-32 object-cover mb-2"
              />
              <p className="text-sm text-gray-600">Current image</p>
            </div>
          )}
          <input
            type="file"
            onChange={(e) => setFeaturedImage(e.target.files[0])}
            className="mt-1 block w-full"
            accept="image/*"
          />
        </div>

        {/* Rich Text Editor */}
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">Content</label>
          <ReactQuill
            value={content}
            onChange={(content) => setContent(content)}
            modules={modules}
            className="h-96 mb-12"
          />
        </div>

        {/* Publish Toggle */}
        <div className="flex items-center">
          <input
            type="checkbox"
            checked={isPublished}
            onChange={(e) => setIsPublished(e.target.checked)}
            className="h-4 w-4 rounded border-gray-300"
          />
          <label className="ml-2 block text-sm text-gray-900">
            Publish this post
          </label>
        </div>

        {/* Submit Button */}
        <div>
          <button
            type="submit"
            disabled={loading}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            {loading ? 'Saving...' : (slug ? 'Update Post' : 'Create Post')}
          </button>
        </div>
      </form>
    </div>
  );
}

export default AdminBlogEditor; 
