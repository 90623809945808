// import React from 'react';

// function Footer() {
//   return (
//     <div className="bg-black py-4">
//       <div className="container mx-auto flex items-center justify-between">
//         <div className="text-xl md:text-3xl mx-5">
//           <a href="/">
//               <span className="logo-letter font-bold text-white">w</span>
//               <lord-icon
//                   src="https://cdn.lordicon.com/dycatgju.json"
//                   trigger="loop"
//                   delay="2000"
//                   colors="primary:#ffffff"
//                   style={{width: "25px", height: "25px", paddingTop: "4px"}}
//               >
//               </lord-icon>
//               <span className="logo-letter font-bold text-white">rkX</span>
//           </a>
//         </div>
//         <div className="text-white text-sm">
//           <p>&copy; {new Date().getFullYear()} writerbeaz. All rights reserved.</p>
//           <p>Privacy Policy</p>
//           <p>Terms of Service</p>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default Footer;


import React from 'react';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <div className="bg-black py-6">
      <div className="container mx-auto">
        <div className="flex flex-col items-center text-center">
          <div className="text-white text-sm space-y-2">
            <p>&copy; {new Date().getFullYear()} writerbeaz. All rights reserved.</p>
            <div className="space-y-1">
              <Link to="/privacy-policy" className="text-white hover:text-gray-300 block">
                Privacy Policy
              </Link>
              <Link to="/terms-of-use" className="text-white hover:text-gray-300 block">
                Terms of Service
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
