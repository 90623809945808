// import React, { useEffect } from 'react';

// const AdComponent = ({ adSlot, format = 'auto' }) => {
//   useEffect(() => {
//     // Load ads when component mounts
//     if (window.adsbygoogle && process.env.NODE_ENV !== 'development') {
//       window.adsbygoogle.push({});
//     }
//   }, []);

//   return (
//     <ins
//       className="adsbygoogle"
//       style={{ display: 'block' }}
//       data-ad-client="YOUR-AD-CLIENT-ID" // Replace with your AdSense client ID
//       data-ad-slot={adSlot}
//       data-ad-format={format}
//       data-full-width-responsive="true"
//     />
//   );
// };

// export default AdComponent; 


import React, { useEffect } from 'react';

const AdComponent = ({ adSlot, format = 'auto' }) => {
  useEffect(() => {
    // Load ads when component mounts
    if (window.adsbygoogle && process.env.NODE_ENV !== 'development') {
      window.adsbygoogle.push({});
    }
  }, []);

  return (
    <ins
      className="adsbygoogle"
      style={{ display: 'block' }}
      data-ad-client="1854380647465998" // Replace with your AdSense client ID
      data-ad-slot={3869436166}
      data-ad-format={"auto"}
      data-full-width-responsive="true"
    />
  );
};

export default AdComponent; 
